<template>
  <tr class="team-row">
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="team-row__cell team-row__cell--id" :style="{ cursor: config.mode == 'select' ? 'default' : 'pointer' }" @click="goToTeam()">
      <div class="team-row__id-container">
        <span class="team-row__id-hash">#</span>
        <span class="team-row__id">{{ props.item.id || '---' }}</span>
      </div>
    </td>
    <td class="team-row__cell team-row__cell--name" :style="{ cursor: config.mode == 'select' ? 'default' : 'pointer' }" @click="goToTeam()">
      <div class="team-row__name-tag" :style="{ borderLeftColor: props.item.color }">
        <v-icon class="team-row__icon" :color="props.item.color">{{props.item.icon}}</v-icon>
        <span class="team-row__title">{{props.item.name || '- - -'}}</span>
      </div>
    </td>
    <td class="team-row__cell team-row__cell--members" :style="{ cursor: config.mode == 'select' ? 'default' : 'pointer' }" @click="goToTeam()">
      <div v-if="props.item.members_count > 0" class="team-row__members-count" 
           :style="{ borderColor: props.item.color, boxShadow: props.item.color === '#FFFFFF' || props.item.color === '#ffffff' ? '0 0 0 1px rgba(0,0,0,0.1)' : 'none' }">
        {{ props.item.members_count }}
      </div>
      <div v-else class="team-row__members-empty">
        Nenhum membro
      </div>
    </td>
    <td class="team-row__cell team-row__cell--talk-use" :style="{ cursor: config.mode == 'select' ? 'default' : 'pointer' }" @click="goToTeam()">
      <v-chip v-if="props.item.talk_use" small color="success" text-color="white" class="team-row__talk-chip">
        <v-icon small left>chat</v-icon>
        Sim
      </v-chip>
      <v-chip v-else small outline class="team-row__talk-chip">
        <v-icon small left>chat_bubble_outline</v-icon>
        Não
      </v-chip>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Teams',
    methods: {
      goToTeam: function () {
        if (this.config.mode != 'select') {
          let route = this.config.config.urlDetails
          route.params['id'] = this.props.item.id
          this.$router.push(route)
        }
      },
      getTextColor: function (corHexadecimal) {
          // Converte a cor hexadecimal para RGB
          const r = parseInt(corHexadecimal.slice(1, 3), 16);
          const g = parseInt(corHexadecimal.slice(3, 5), 16);
          const b = parseInt(corHexadecimal.slice(5, 7), 16);

          // Calcula o valor de luminosidade usando a fórmula YIQ
          const luminosidade = (r * 299 + g * 587 + b * 114) / 1000;

          // Define um valor de limiar para determinar a cor do texto
          const limiar = 128;

          // Verifica se a luminosidade é maior que o limiar
          if (luminosidade > limiar) {
              // Se a cor de fundo for clara, retorna cor escura
              return "#000000"; // Cor preta
          } else {
              // Se a cor de fundo for escura, retorna cor clara
              return "#FFFFFF"; // Cor branca
          }
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>

<style scoped>
.team-row {
  transition: background-color 0.2s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* Tema claro */
.theme--light .team-row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

/* Tema escuro */
.theme--dark .team-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.theme--dark .team-row:hover {
  background-color: rgba(255, 255, 255, 0.04);
}

.team-row__cell {
  padding: 14px 10px;
  vertical-align: middle;
  font-family: 'Roboto', sans-serif;
}

.team-row__cell--id {
  text-align: left;
  width: 80px;
}

.team-row__id-container {
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
}

.team-row__id-container:hover {
  transform: translateX(2px);
}

.team-row__id-hash {
  font-size: 13px;
  font-weight: 600;
  opacity: 0.5;
  margin-right: 2px;
}

.team-row__id {
  font-size: 13px;
  letter-spacing: 0.3px;
  opacity: 0.7;
  font-weight: 500;
}

.team-row__cell--name {
  text-align: left;
}

.team-row__name-tag {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-left: 4px solid;
  border-radius: 3px;
  transition: transform 0.2s ease;
  max-width: 300px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

/* Tema claro */
.theme--light .team-row__name-tag {
  background-color: rgba(0, 0, 0, 0.03);
}

/* Tema escuro */
.theme--dark .team-row__name-tag {
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05);
}

.team-row__name-tag:hover {
  transform: translateX(3px);
}

.team-row__icon {
  margin-right: 12px;
  font-size: 18px;
}

.team-row__title {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-row__cell--members {
  text-align: center;
  width: 120px;
}

.team-row__members-count {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 10px;
  border: 1px solid;
  border-radius: 12px;
  min-width: 32px;
  transition: transform 0.2s ease;
}

/* Tema claro */
.theme--light .team-row__members-count {
  background-color: #ffffff;
}

/* Tema escuro */
.theme--dark .team-row__members-count {
  background-color: #424242;
}

.team-row__members-count:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.team-row__members-empty {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  opacity: 0.5;
}

.team-row__cell--talk-use {
  text-align: center;
  width: 120px;
}

.team-row__talk-chip {
  font-size: 12px;
  padding: 4px 10px;
}
</style>
